const state = () => {
  return {
    zbStatus: false,
  }
}

const mutations = {
  SetZbStatus(state, status) {
    state.zbStatus = status
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
