import {
  sendNewAreaReward,
  getServerByNewArea,
  getListByNewArea,
  getNameByNewArea,
  captchaByNewArea,
  sendAllNewAreaRewards,
} from '@/api/new_area'

const state = () => {
  return {}
}

const mutations = {}

const actions = {
  // eslint-disable-next-line
  sendNewAreaReward({}, data) {
    return sendNewAreaReward(data)
  },
  // eslint-disable-next-line
  getServerByNewArea() {
    return getServerByNewArea()
  },
  // eslint-disable-next-line
  getListByNewArea() {
    return getListByNewArea()
  },
  // eslint-disable-next-line
  getNameByNewArea({}, data) {
    return getNameByNewArea(data)
  },
  // eslint-disable-next-line
  captchaByNewArea() {
    return captchaByNewArea()
  },
  // eslint-disable-next-line
  sendAllNewAreaRewards({}, data) {
    return sendAllNewAreaRewards(data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
