<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {},
  created() {
    this.getSetting().catch((err) => {
      console.log(err)
    })
  },
  methods: {
    ...mapActions('setting', ['getSetting']),
  },
}
</script>
