import request from '@/utils/request'

export function createOrder(data) {
  return request({
    url: '/api/tlbb/createOrder',
    method: 'post',
    data,
  })
}

export function getOrderList(data) {
  return request({
    url: '/api/tlbb/order/list',
    method: 'post',
    data,
  })
}

export function getPayMethod() {
  return request({
    url: '/api/pay/paymethods',
    method: 'get',
  })
}
