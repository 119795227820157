import { getWechatAppid, getWechatPayParams } from '@/api/wechat'

const state = () => {
  return {}
}

const mutations = {}

const actions = {
  // eslint-disable-next-line
  getWechatAppid() {
    return getWechatAppid()
  },
  // eslint-disable-next-line
  getWechatPayParams({}, data) {
    return getWechatPayParams(data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
