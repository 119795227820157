import request from '@/utils/request'

export function getWechatAppid() {
  return request({
    url: '/api/wechat/getAppid',
    method: 'get',
  })
}

export function getWechatPayParams(data) {
  return request({
    url: '/api/pay/wechat/params',
    method: 'post',
    data,
  })
}
