import Tlbb from "@/components/TlbbLayout.vue";
import EmptyLayout from "@/components/EmptyLayout";

export default [
  {
    path: "/",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: 'tlbb-index' */ "@/views/index.vue"),
    meta: {
      title: "首页"
    }
  },
  {
    path: "/tlbb",
    name: "Tlbb",
    component: Tlbb,
    children: [
      {
        path: "index3",
        name: "TlbbIndex",
        component: () =>
          import(/* webpackChunkName: 'tlbb-index' */ "@/views/tlbb/index.vue"),
        meta: {
          title: "首页"
        }
      },
      {
        path: "index5",
        name: "TlbbIndex",
        component: () =>
          import(/* webpackChunkName: 'tlbb-index' */ "@/views/tlbb/index.vue"),
        meta: {
          title: "首页"
        }
      },
      {
        path: "reward",
        name: "TlbbReward",
        meta: {
          title: "奖励领取"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-reward' */ "@/views/tlbb/reward.vue"
          )
      },
      {
        path: "award",
        name: "TlbbAward",
        meta: {
          title: "充值奖励说明"
        },
        component: () =>
          import(/* webpackChunkName: 'tlbb-award' */ "@/views/tlbb/award.vue")
      },
      {
        path: "register",
        name: "TlbbRegister",
        meta: {
          title: "注册"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-register' */ "@/views/tlbb/register.vue"
          )
      },
      {
        path: "pay",
        name: "TlbbPay",
        meta: {
          title: "充值"
        },
        component: () =>
          import(/* webpackChunkName: 'tlbb-pay' */ "@/views/tlbb/pay.vue")
      },
      {
        path: "pay/result",
        name: "PayResult",
        meta: {
          title: "支付结果"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-pay-result' */ "@/views/tlbb/payResult.vue"
          )
      },
      {
        path: "downloadtlbb1",
        name: "TlbbDownload",
        meta: {
          title: "下载"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-download' */ "@/views/tlbb/download.vue"
          )
      },
      {
        path: "game_pay_desc",
        name: "PayRewardDesc",
        meta: {
          title: "充值奖励介绍"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-pay-desc' */ "@/views/tlbb/payRewardDesc.vue"
          )
      },
      {
        path: "game_ka_desc",
        name: "KaRewardDesc",
        meta: {
          title: "卡类奖励介绍"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-ka-desc' */ "@/views/tlbb/kaRewardDesc.vue"
          )
      },
      {
        path: "hd_ka_desc",
        name: "HdRewardDesc",
        meta: {
          title: "活动卡奖励介绍"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-ka-desc' */ "@/views/tlbb/hdRewardDesc.vue"
          )
      },
      {
        path: "invitation_desc",
        name: "InvitationDesc",
        meta: {
          title: "积分奖励介绍"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-invitation-desc' */ "@/views/tlbb/invitationDesc.vue"
          )
      },
      {
        path: "order_list",
        name: "OrderList",
        meta: {
          title: "订单查询"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-order-list' */ "@/views/tlbb/order.vue"
          )
      },
      {
        path: "gl",
        name: "Gl",
        meta: {
          title: "新区攻略"
        },
        component: () =>
          import(/* webpackChunkName: 'tlbb-gl' */ "@/views/tlbb/gl.vue")
      },
      {
        path: "new_reward",
        name: "NewReward",
        meta: {
          title: "新手奖励"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-new-reward' */ "@/views/tlbb/newReward.vue"
          )
      },
      {
        path: "new_area_reward",
        name: "NewAreaReward",
        meta: {
          title: "新区福利"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-new-reward' */ "@/views/tlbb/newAreaReward.vue"
          )
      }
    ]
  },
  {
    path: "/oppo",
    name: "Oppo",
    component: EmptyLayout,
    children: [
      {
        path: "addAccounts",
        name: "AddAccounts",
        meta: {
          title: "添加Oppo账号"
        },
        component: () =>
          import(
            /* webpackChunkName: 'tlbb-new-reward' */ "@/views/oppoAddAccount.vue"
          )
      }
    ]
  }
];
