import request from "@/utils/request";

export function getServers() {
  return request({
    url: "/api/tlbb/getServers",
    method: "get"
  });
}

export function getName(data) {
  return request({
    url: "/api/tlbb/getRoleList",
    method: "post",
    data
  });
}

export function getProductList() {
  return request({
    url: "/api/tlbb/getProductList",
    method: "get"
  });
}

export function getBRewardCaptcha() {
  return request({
    url: "/api/tlbb/breward/capcha",
    method: "post",
    data: {}
  });
}

export function sendReward(data) {
  return request({
    url: "/api/tlbb/sendBreward",
    method: "post",
    data
  });
}

export function bConfig() {
  return request({
    url: "/api/tlbb/bConfig"
  });
}

export function nConfig() {
  return request({
    url: "/api/tlbb/nConfig"
  });
}

export function getDownloadUrl() {
  return request({
    url: "/api/tlbb/download/andriod"
  });
}

export function sendNReward(data) {
  return request({
    url: "/api/tlbb/sendNReward",
    method: "post",
    data
  });
}
