import { createOrder, getOrderList, getPayMethod } from '@/api/order'

const state = () => {
  return {}
}

const mutations = {}

const actions = {
  // eslint-disable-next-line
  createOrder({}, data) {
    return createOrder(data)
  },
  // eslint-disable-next-line
  getOrderList({}, data) {
    return getOrderList(data)
  },
  // eslint-disable-next-line
  getPayMethod({}) {
    return getPayMethod()
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
