import request from '@/utils/request'

export function sendNewAreaReward(data) {
  return request({
    url: '/api/tlbb/new_area/reward',
    method: 'post',
    data,
  })
}

export function getServerByNewArea() {
  return request({
    url: '/api/tlbb/new_area/servers',
    method: 'get',
  })
}

export function getListByNewArea() {
  return request({
    url: '/api/tlbb/new_area/rewards',
    method: 'get',
  })
}

export function getNameByNewArea(data) {
  return request({
    url: '/api/tlbb/new_area/roles',
    method: 'post',
    data,
  })
}

export function captchaByNewArea() {
  return request({
    url: '/api/tlbb/new_area/captcha',
    method: 'post',
  })
}

export function sendAllNewAreaRewards(data) {
  return request({
    url: '/api/tlbb/new_area/all_reward',
    method: 'post',
    data,
  })
}
