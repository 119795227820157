<template>
  <div>
    <div class="nav-header flex align-center justify-between">
      <div class="flex flex-row align-center" @click="menuStatus = !menuStatus">
        <van-icon name="wap-nav" v-if="!menuStatus" />
        <van-icon name="cross" v-else />
        <span style="font-size: 12px;margin-left: 5px;" v-if="!menuStatus"
          >菜单</span
        >
      </div>

      <div class="flex flex-row align-center">
        <van-image :src="LogoImage" class="logo-image"></van-image>
        <span style="margin-left: 5px;">{{ name }}</span>
      </div>
      <van-button
        size="mini"
        type="danger"
        round
        v-if="$route.name !== 'TlbbDownload'"
        @click="goPage({ name: '下载', routeName: 'TlbbDownload' })"
        >立即下载</van-button
      >
      <transition name="van-slide-left">
        <div class="menus" v-show="menuStatus">
          <div
            class="menu-item"
            v-for="(page, idx) in menu"
            :key="idx"
            @click="goPage(page)"
            :class="{ 'active-route': $route.name === page.routeName }"
          >
            <span>{{ page.name }}</span>
          </div>
        </div>
      </transition>
    </div>
    <div class="place"></div>
  </div>
</template>
<script>
import { Image, Button } from "vant";
import LogoImage from "@/assets/images/logo.png";
import { name } from "@/config/settings";
import { mapState } from "vuex";

export default {
  components: {
    "van-image": Image,
    "van-button": Button
  },
  data() {
    return {
      LogoImage,
      name,
      menuStatus: false,
      menu: [
        {
          name: "首页",
          routeName: "TlbbIndex"
        },
        {
          name: "注册",
          routeName: "TlbbRegister"
        },
        {
          name: "充值",
          routeName: "TlbbPay"
        },
        {
          name: "下载",
          routeName: "TlbbDownload"
        },
        {
          name: "订单查询",
          routeName: "OrderList"
        },
        {
          name: "新手礼包",
          routeName: "NewReward"
        },
        // {
        //   name: '新区福利',
        //   routeName: 'NewAreaReward',
        // },
        {
          name: "充值奖励说明",
          routeName: "PayRewardDesc"
        }
        // {
        //   name: '新区攻略',
        //   routeName: 'Gl',
        // },
        // {
        //   name: "卡类领取",
        //   routeName: "TlbbReward"
        // }
      ]
    };
  },
  computed: {
    ...mapState({ setting: state => state.setting.setting })
  },
  mounted() {},
  methods: {
    goPage(page) {
      if (this.$route.name === page.routeName) return;
      if (page.routeName) {
        if (page.routeName === "TlbbDownload") {
          if (!this.setting.downloadBySelf) {
            if (this.setting.downloadUrl) {
              window.location.href = this.setting.downloadUrl;
            } else {
              this.$dialog({
                title: "提示",
                message: "请配置下载地址"
              });
            }
          } else {
            this.$router.push({ name: page.routeName });
          }
        } else {
          this.$router.push({ name: page.routeName });
        }

        this.menuStatus = false;

        return;
      }
      if (page.link) {
        window.location.href = page.link;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.nav-header {
  line-height: 50px;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 2000;
  border-bottom: 1px solid #e5e5e5;

  .logo-image {
    width: 25px;
    height: 25px;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .menus {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    bottom: -100vh;
    left: 0;
    z-index: 2000;

    .menu-item {
      text-align: center;
      color: #fff;

      border-bottom: 1px solid #333;

      &.active-route {
        color: #666;
      }
    }
  }
}

.place {
  height: 50px;
  margin-bottom: 10px;
}
</style>
