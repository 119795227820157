import Clipboard from 'clipboard'
import { Notify } from 'vant'

function clipboardSuccess() {
  Notify({ type: 'primary', message: '复制成功' })
}

function clipboardError() {
  Notify({ type: 'danger', message: '复制失败' })
}

/**
 * @description 复制数据
 * @param text
 * @param event
 */
export default function handleClipboard(text, event) {
  const clipboard = new Clipboard(event.target, {
    text: () => text,
  })
  clipboard.on('success', () => {
    clipboardSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
