import axios from 'axios'
import { Dialog } from 'vant'
import qs from 'qs'

const service = axios.create({
  timeout: 20000,
})

const errorMsg = (message) => {
  return Dialog({
    title: '错误提示',
    message,
  })
}

export default ({
  method = 'get',
  data = {},
  url,
  contentType = 'application/json;charset=UTF-8',
}) => {
  return new Promise((resolve, reject) => {
    service({
      method,
      data:
        contentType === 'application/x-www-form-urlencoded; charset=UTF-8'
          ? qs.stringify(data)
          : data,
      url,
      headers: {
        'Content-Type': contentType,
      },
    })
      .then((response) => {
        const { data } = response
        const { code, msg } = data
        if (code !== 0) {
          errorMsg(msg || `请求失败`)
          reject({ code, msg, url } || 'Error')
        } else {
          resolve(data)
        }
      })
      .catch((error) => {
        /*网络连接过程异常处理*/
        let { message } = error
        switch (message) {
          case 'Network Error':
            message = '连接异常'
            break
          case 'timeout':
            message = '请求超时'
            break
          case 'Request failed with status code':
            message = message.substr(message.length - 3) + '异常'
            break
        }
        errorMsg(message || '未知异常')
        reject(error)
      })
  })
}
