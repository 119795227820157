import { addOppoAccounts } from '@/api/oppo'

const state = () => {
  return {}
}

const mutations = {}

const actions = {
  // eslint-disable-next-line
  addOppoAccounts({}, data) {
    return addOppoAccounts(data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
