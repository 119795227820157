import { getSetting } from '@/api/setting'

const state = () => {
  return {
    setting: {
      downloadUrl: '',
      downloadBySelf: false,
      wechatId: '',
      qqNo: '',
      kefuQrUrl: '',
      groupQrUrl: '',
      zbImageUrl: '',
      popContent: '',
    },
  }
}

const mutations = {
  CHANGE_SETTING(state, setting) {
    state.setting = setting
  },
}

const actions = {
  // eslint-disable-next-line
  async getSetting({ commit }) {
    try {
      let { data } = await getSetting()
      commit('CHANGE_SETTING', data.data)
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
