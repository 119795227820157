import {
  getServers,
  getName,
  getBRewardCaptcha,
  sendReward,
  sendNReward,
  getProductList,
  bConfig,
  getDownloadUrl,
  nConfig
} from "@/api/reword";

const state = () => {
  return {};
};

const mutations = {};

const actions = {
  getServers() {
    return getServers();
  },
  // eslint-disable-next-line
  getName({}, data) {
    return getName(data);
  },
  getBRewardCaptcha() {
    return getBRewardCaptcha();
  },
  // eslint-disable-next-line
  sendReward({}, data) {
    return sendReward(data);
  },
  // eslint-disable-next-line
  sendNReward({}, data) {
    return sendNReward(data);
  },
  getProductList() {
    return getProductList();
  },
  bConfig() {
    return bConfig();
  },
  nConfig() {
    return nConfig();
  },
  getDownloadUrl() {
    return getDownloadUrl();
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
