import { render, staticRenderFns } from "./TlbbLayout.vue?vue&type=template&id=acbb2cba&scoped=true&"
import script from "./TlbbLayout.vue?vue&type=script&lang=js&"
export * from "./TlbbLayout.vue?vue&type=script&lang=js&"
import style0 from "./TlbbLayout.vue?vue&type=style&index=0&id=acbb2cba&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acbb2cba",
  null
  
)

export default component.exports